const ArrowDownward: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 12L18.59 10.59L13 16.17V4H11V16.17L5.42 10.58L4 12L12 20L20 12Z" />
  </svg>
);
ArrowDownward.displayName = 'SharedElementIconsArrowDownward';
export default ArrowDownward;
