const Money: React.FC = () => (
  <svg width="18" height="18">
    <use
      href="/imgs/icons/maintenance.svg#money"
      xlinkHref="/imgs/icons/maintenance.svg#money"
    />
  </svg>
);
Money.displayName = 'SharedElementIconsMoney';
export default Money;
