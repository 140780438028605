const FacebookBlue: React.FC = () => (
  <svg width="24" height="24">
    <use
      href="/imgs/icons/common.svg#finance-facebook"
      xlinkHref="/imgs/icons/common.svg#finance-facebook"
    />
  </svg>
);
FacebookBlue.displayName = 'SharedElementIconsSocialFacebookBlue';
export default FacebookBlue;
