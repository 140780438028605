const Youtube: React.FC = () => (
  <svg width="20" height="20">
    <use
      href="/imgs/icons/common.svg#youtube"
      xlinkHref="/imgs/icons/common.svg#youtube"
    />
  </svg>
);
Youtube.displayName = 'SharedElementIconsSocialYoutube';
export default Youtube;
