const ShareFacebook: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.5502 21.75V11.9989H16.242L16.5987 8.63856H13.5502L13.5548 6.9567C13.5548 6.08028 13.6381 5.61068 14.8969 5.61068H16.5796V2.25H13.8875C10.6538 2.25 9.51566 3.88011 9.51566 6.62143V8.63894H7.5V11.9992H9.51566V21.75H13.5502Z" />
  </svg>
);
ShareFacebook.displayName = 'SharedElementIconsShareFacebook';
export default ShareFacebook;
