const GiftReward: React.FC = () => (
  <svg width="24" height="24">
    <use
      href="/imgs/icons/maintenance.svg#gift"
      xlinkHref="/imgs/icons/maintenance.svg#gift"
    />
  </svg>
);
GiftReward.displayName = 'SharedElementIconsGiftReward';
export default GiftReward;
