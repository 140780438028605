const Order: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 22H5C3.897 22 3 21.103 3 20V5C3 3.897 3.897 3 5 3H7C7 2.447 7.447 2 8 2H16C16.553 2 17 2.447 17 3H19C20.103 3 21 3.897 21 5V20C21 21.103 20.103 22 19 22ZM7 5H5V20H19V5H17V7H7V5ZM9.20697 11.793L11 13.586L14.793 9.79297L16.207 11.207L11 16.414L7.79297 13.207L9.20697 11.793Z" />
  </svg>
);
Order.displayName = 'SharedElementIconsOrder';
export default Order;
