const ArrowUpward: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 12L5.41 13.41L11 7.83V20H13V7.83L18.58 13.42L20 12L12 4L4 12Z" />
  </svg>
);
ArrowUpward.displayName = 'SharedElementIconsArrowUpward';
export default ArrowUpward;
