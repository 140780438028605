const ExpandLess: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 8L6 14L7.41 15.41L12 10.83L16.59 15.41L18 14L12 8Z" />
  </svg>
);
ExpandLess.displayName = 'SharedElementIconsExpandLess';
export default ExpandLess;
