const Torque: React.FC = () => (
  <svg width="24" height="24">
    <use
      href="/imgs/icons/maintenance.svg#torque"
      xlinkHref="/imgs/icons/maintenance.svg#torque"
    />
  </svg>
);
Torque.displayName = 'SharedElementIconsTorque';
export default Torque;
