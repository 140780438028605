const Forward: React.FC = () => (
  <svg
    width="45"
    height="38"
    viewBox="0 0 45 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.41575 3.22044C2.78259 1.8536 4.99866 1.8536 6.3655 3.22044L18.9631 15.818C20.7204 17.5754 20.7204 20.4246 18.9631 22.182L6.3655 34.7796C4.99866 36.1464 2.78259 36.1464 1.41575 34.7796C0.0489163 33.4127 0.0489163 31.1966 1.41575 29.8298L12.2456 19L1.41575 8.17019C0.0489163 6.80335 0.0489163 4.58727 1.41575 3.22044Z"
      fill="white"
    />
    <path
      d="M25.3064 3.22044C26.6732 1.8536 28.8893 1.8536 30.2561 3.22044L42.8537 15.818C44.6111 17.5754 44.6111 20.4246 42.8537 22.182L30.2561 34.7796C28.8893 36.1464 26.6732 36.1464 25.3064 34.7796C23.9395 33.4127 23.9395 31.1966 25.3064 29.8298L36.1362 19L25.3064 8.17019C23.9395 6.80335 23.9395 4.58727 25.3064 3.22044Z"
      fill="white"
    />
  </svg>
);
Forward.displayName = 'SharedElementIconsForward';
export default Forward;
