const Instagram: React.FC = () => (
  <svg width="20" height="20">
    <use
      href="/imgs/icons/common.svg#instagram"
      xlinkHref="/imgs/icons/common.svg#instagram"
    />
  </svg>
);
Instagram.displayName = 'SharedElementIconsSocialInstagram';
export default Instagram;
