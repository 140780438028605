const Distance: React.FC = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 20H15.344C15.7 20.753 16.136 21.422 16.593 22H7C4.519 22 2.5 19.981 2.5 17.5C2.5 15.019 4.519 13 7 13H14C14.827 13 15.5 12.327 15.5 11.5C15.5 10.673 14.827 10 14 10H7.785C8.271 9.443 8.748 8.771 9.139 8H14C15.93 8 17.5 9.57 17.5 11.5C17.5 13.43 15.93 15 14 15H7C5.621 15 4.5 16.121 4.5 17.5C4.5 18.879 5.621 20 7 20ZM2.5 5C2.5 3.346 3.846 2 5.5 2C7.154 2 8.5 3.346 8.5 5C8.5 8.187 5.5 10 5.5 10C5.5 10 2.5 8.188 2.5 5ZM4 5C4 5.828 4.672 6.5 5.5 6.5C6.328 6.5 7 5.828 7 5C7 4.172 6.328 3.5 5.5 3.5C4.672 3.5 4 4.172 4 5ZM16.5 17C16.5 15.346 17.846 14 19.5 14C21.154 14 22.5 15.346 22.5 17C22.5 20.187 19.5 22 19.5 22C19.5 22 16.5 20.188 16.5 17ZM18 17C18 17.828 18.672 18.5 19.5 18.5C20.328 18.5 21 17.828 21 17C21 16.172 20.328 15.5 19.5 15.5C18.672 15.5 18 16.172 18 17Z"
      fill="#F6F6F6"
    />
  </svg>
);
Distance.displayName = 'SharedElementIconsDistance';
export default Distance;
