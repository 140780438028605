const Slope: React.FC = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 10.414L14.5 14.414L20.207 8.707L22.5 11V5H16.5L18.793 7.293L14.5 11.586L10.5 7.586L2.79297 15.293L4.20697 16.707L10.5 10.414Z"
      fill="white"
    />
  </svg>
);
Slope.displayName = 'SharedElementIconsSlope';
export default Slope;
