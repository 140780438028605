const Check: React.FC = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 14.0001L2 9.00008L3.41 7.59008L7 11.1701L14.59 3.58008L16 5.00008L7 14.0001Z" />
  </svg>
);
Check.displayName = 'SharedElementIconsCheck';
export default Check;
