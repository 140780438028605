const ArrowForward2: React.FC = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.9989 10.8052L25.1789 13.6252L34.3389 22.8052H5.99902V26.8052H34.3389L25.1589 35.9852L27.9989 38.8052L41.9989 24.8052L27.9989 10.8052Z"
      fill="white"
    />
  </svg>
);
ArrowForward2.displayName = 'SharedElementIconsArrowForward2';
export default ArrowForward2;
