const Coupon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21 5H20H18H6H4H3C2.447 5 2 5.447 2 6V7V8.938V10H2.893C3.889 10 4.813 10.681 4.973 11.664C5.177 12.917 4.215 14 3 14H2V15.062V17V18C2 18.553 2.447 19 3 19H4H6H18H20H21C21.553 19 22 18.553 22 18V17V15.062V14C22 14 21.553 14 21 14C19.785 14 18.823 12.917 19.027 11.664C19.187 10.681 20.111 10 21.107 10H22V8.938V7V6C22 5.447 21.553 5 21 5ZM9 9C9.553 9 10 9.447 10 10C10 10.553 9.553 11 9 11C8.447 11 8 10.553 8 10C8 9.447 8.447 9 9 9ZM8.2 15.4L14.2 7.4L15.8 8.6L9.8 16.6L8.2 15.4ZM15 15C14.447 15 14 14.553 14 14C14 13.447 14.447 13 15 13C15.553 13 16 13.447 16 14C16 14.553 15.553 15 15 15Z" />
  </svg>
);
Coupon.displayName = 'SharedElementIconsCoupon';
export default Coupon;
