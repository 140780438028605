const Facebook: React.FC = () => (
  <svg width="20" height="20">
    <use
      href="/imgs/icons/common.svg#facebook"
      xlinkHref="/imgs/icons/common.svg#facebook"
    />
  </svg>
);
Facebook.displayName = 'SharedElementIconsSocialFacebook';
export default Facebook;
