const LineGreen: React.FC = () => (
  <svg width="24" height="24">
    <use
      href="/imgs/icons/common.svg#finance-line"
      xlinkHref="/imgs/icons/common.svg#finance-line"
    />
  </svg>
);
LineGreen.displayName = 'SharedElementIconsSocialLineGreen';
export default LineGreen;
