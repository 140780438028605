const PhotoLibraryOutLine: React.FC = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.5 4.28394V16.2839H8.5V4.28394H20.5ZM20.5 2.28394H8.5C7.4 2.28394 6.5 3.18394 6.5 4.28394V16.2839C6.5 17.3839 7.4 18.2839 8.5 18.2839H20.5C21.6 18.2839 22.5 17.3839 22.5 16.2839V4.28394C22.5 3.18394 21.6 2.28394 20.5 2.28394ZM12 11.9539L13.69 14.2139L16.17 11.1139L19.5 15.2839H9.5L12 11.9539ZM2.5 6.28394V20.2839C2.5 21.3839 3.4 22.2839 4.5 22.2839H18.5V20.2839H4.5V6.28394H2.5Z" />
  </svg>
);
PhotoLibraryOutLine.displayName = 'SharedElementIconsPhotoLibraryOutline';
export default PhotoLibraryOutLine;
