const Icon: React.FC = () => (
  <svg
    width="12"
    height="6"
    viewBox="0 0 12 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0L6 6L12 0H0Z" />
  </svg>
);
Icon.displayName = 'SharedElementIconsTriangleDown';
export default Icon;
