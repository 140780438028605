const Pickup: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22.9263 10.5608L19.5789 10.0371L17.4316 5.52078C17.2421 5.0626 16.8 4.80078 16.2947 4.80078H11.4947C11.4947 4.80078 10.8 4.80078 10.8 5.65169C10.7368 6.17533 10.7368 6.76442 10.7368 6.76442V9.3826H6.31579H1.26316C0.568421 9.3826 0 9.97169 0 10.6917V15.2735C0 15.9935 0.568421 16.5826 1.26316 16.5826H3.15789H3.22105C3.53684 18.0881 4.8 19.2008 6.31579 19.2008C7.83158 19.2008 9.09474 18.0881 9.41053 16.5826H15.2211C15.5368 18.0881 16.8 19.2008 18.3158 19.2008C19.8316 19.2008 21.0947 18.0881 21.4105 16.5826H22.7368C23.4316 16.5826 24 15.9935 24 15.2735V11.8044C24 11.1499 23.5579 10.6262 22.9263 10.5608ZM6.31579 17.2371C5.62105 17.2371 5.05263 16.6481 5.05263 15.9281C5.05263 15.2081 5.62105 14.619 6.31579 14.619C7.01053 14.619 7.57895 15.2081 7.57895 15.9281C7.57895 16.6481 7.01053 17.2371 6.31579 17.2371ZM12.6316 9.88112V6.76442H15.7895L16.9091 9.88112H12.6316ZM18.3158 17.2371C17.6211 17.2371 17.0526 16.6481 17.0526 15.9281C17.0526 15.2081 17.6211 14.619 18.3158 14.619C19.0105 14.619 19.5789 15.2081 19.5789 15.9281C19.5789 16.6481 19.0105 17.2371 18.3158 17.2371Z" />
  </svg>
);
Pickup.displayName = 'SharedElementIconsPickup';
export default Pickup;
