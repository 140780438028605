const Fix: React.FC = () => (
  <svg width="24" height="24">
    <use
      href="/imgs/icons/maintenance.svg#fix"
      xlinkHref="/imgs/icons/maintenance.svg#fix"
    />
  </svg>
);
Fix.displayName = 'SharedElementIconsFix';
export default Fix;
