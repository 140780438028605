const Receipt: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18 11H21C21.553 11 22 11.447 22 12V18C22 19.654 20.654 21 19 21H5C3.346 21 2 19.654 2 18V4C2 3.447 2.447 3 3 3H17C17.553 3 18 3.447 18 4V11ZM4 18C4 18.552 4.448 19 5 19H16.171C16.061 18.688 16 18.351 16 18V5H4V18ZM19 19C19.552 19 20 18.552 20 18V13H18V18C18 18.552 18.448 19 19 19ZM14 7H6V9H14V7ZM14 11H6V13H14V11ZM14 15H11V17H14V15Z" />
  </svg>
);
Receipt.displayName = 'SharedElementIconsReceipt';
export default Receipt;
