const Filter: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 5H22V7H13V5ZM2 7H9V9H11V3H9V5H2V7ZM9 17H22V19H9V17ZM19 11H22V13H19V11ZM17 15V9.012H15V11H2V13H15V15H17ZM7 21V15H5V17H2V19H5V21H7Z" />
  </svg>
);
Filter.displayName = 'SharedElementIconsFilter';
export default Filter;
