const Checkmark: React.FC = () => (
  <svg
    width="54"
    height="55"
    viewBox="0 0 54 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.9999 0.835938C12.2959 0.835938 0.333252 12.7986 0.333252 27.5026C0.333252 42.2066 12.2959 54.1693 26.9999 54.1693C41.7039 54.1693 53.6666 42.2066 53.6666 27.5026C53.6666 12.7986 41.7039 0.835938 26.9999 0.835938ZM21.6693 39.2706L11.7679 29.3906L15.5333 25.6146L21.6639 31.7346L35.7813 17.6173L39.5519 21.3879L21.6693 39.2706Z"
      fill="#00C380"
    />
  </svg>
);
Checkmark.displayName = 'SharedElementIconsCheckmark';
export default Checkmark;
