const FaceSad: React.FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.66406 15.9998C2.66406 8.64784 8.6454 2.6665 15.9974 2.6665C23.3494 2.6665 29.3307 8.64784 29.3307 15.9998C29.3307 23.3518 23.3494 29.3332 15.9974 29.3332C8.6454 29.3332 2.66406 23.3518 2.66406 15.9998ZM5.33073 15.9998C5.33073 21.8812 10.1161 26.6665 15.9974 26.6665C21.8787 26.6665 26.6641 21.8812 26.6641 15.9998C26.6641 10.1185 21.8787 5.33317 15.9974 5.33317C10.1161 5.33317 5.33073 10.1185 5.33073 15.9998ZM13.3307 13.9998C13.3307 15.1044 12.4353 15.9998 11.3307 15.9998C10.2262 15.9998 9.33073 15.1044 9.33073 13.9998C9.33073 12.8953 10.2262 11.9998 11.3307 11.9998C12.4353 11.9998 13.3307 12.8953 13.3307 13.9998ZM20.6547 15.9812C21.7541 15.9812 22.6454 15.0899 22.6454 13.9905C22.6454 12.8911 21.7541 11.9998 20.6547 11.9998C19.5553 11.9998 18.6641 12.8911 18.6641 13.9905C18.6641 15.0899 19.5553 15.9812 20.6547 15.9812ZM10.6641 22.6665C10.6641 22.6665 11.9974 18.6665 15.9974 18.6665C19.9974 18.6665 21.3307 22.6665 21.3307 22.6665H10.6641Z" />
  </svg>
);
FaceSad.displayName = 'SharedElementIconsFaceSad';
export default FaceSad;
