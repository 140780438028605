import { IOptions, IRatio } from '@/types/shared';

export const getImageOptions = (_options: IOptions) => {
  let urlWithOptions = `${_options.src}?format=pjpg&auto=webp`;
  const options: string[] = [];
  if (_options.width) {
    options.push(`width=${_options.width}`);
  }

  if (_options.height) {
    options.push(`height=${_options.height}`);
  }

  if (_options.crop) {
    options.push(`crop=${_options.crop}`);
  }

  if (_options.fit) {
    options.push(`fit=crop`);
  }

  if (_options.dpr) {
    options.push(`dpr=${_options.dpr}`);
  }

  if (_options.blur) {
    options.push(`blur=${_options.blur}`);
  }

  options.push(`quality=${_options.quality}`);

  options.forEach((o) => {
    urlWithOptions += `&${o}`;
  });

  return urlWithOptions;
};

export interface INextImageOptions {
  src: string;
  width?: number;
  height?: number;
  cropByRatio?: IRatio;
  blur?: number;
  bgColor?: string;
  quality?: number;
}

export const getNextImageOptions = (_options: INextImageOptions) => {
  let URL_WITH_OPTIONS = `${_options.src}?format=pjpg&auto=webp`;

  const OPTIONS: string[] = [];

  if (_options.width) {
    OPTIONS.push(`width=${_options.width}`);
  }

  if (_options.cropByRatio) {
    OPTIONS.push(`crop=${_options.cropByRatio}`);
  }

  if (_options.blur) {
    OPTIONS.push(`blur=${_options.blur}`);
  }

  if (_options.bgColor) {
    OPTIONS.push(`bg-color=${_options.bgColor}`);
  }

  OPTIONS.push(`quality=${_options.quality || 75}`);

  OPTIONS.forEach((o) => {
    URL_WITH_OPTIONS += `&${o}`;
  });

  return URL_WITH_OPTIONS;
};
