const ChevronNorthEast: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 5V7H15.59L4 18.59L5.41 20L17 8.41V15H19V5H9Z" fill="white" />
  </svg>
);
ChevronNorthEast.displayName = 'SharedElementIconsChevronNorthEast';
export default ChevronNorthEast;
