const ManualDownloadGray: React.FC = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16.875" r="16" fill="#EBEBEB" />
    <path
      d="M21.3334 15.0972H17.7778V8.875H14.2223V15.0972H10.6667L16.0001 20.4306L21.3334 15.0972ZM9.77783 22.2083V23.9861H22.2223V22.2083H9.77783Z"
      fill="white"
    />
  </svg>
);
ManualDownloadGray.displayName = 'SharedElementIconsManualDownloadGray';
export default ManualDownloadGray;
