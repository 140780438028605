const Line: React.FC = () => (
  <svg width="20" height="20">
    <use
      href="/imgs/icons/common.svg#line"
      xlinkHref="/imgs/icons/common.svg#line"
    />
  </svg>
);
Line.displayName = 'SharedElementIconsSocialLine';
export default Line;
