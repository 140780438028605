const OpenInNew: React.FC = () => (
  <svg width="24" height="24">
    <use
      href="/imgs/icons/common.svg#open-in-new"
      xlinkHref="/imgs/icons/common.svg#open-in-new"
    />
  </svg>
);
OpenInNew.displayName = 'SharedElementIconsOpenInNew';
export default OpenInNew;
