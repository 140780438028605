import Head from 'next/head';
import { useEffect, useState } from 'react';

import { IconButton } from '@/components/shared/element/icon-button';
import { getIcon, Icon } from '@/components/shared/element/icons';
import { useInViewRef } from '@/components/shared/utility/hooks';
import { getImageOptions } from '@/components/shared/utility/image-contentstack';
import { styled } from '@/stitches.config';
import { IRatio } from '@/types/shared/my24/ratio';
import { ITest } from '@/types/tracking';

const CardOverlay = styled('div', {
  '@lg': {
    display: 'block',
    position: 'absolute',
    left: '-1px',
    top: '-1px',
    width: 'calc(100% + 2px)',
    height: 'calc(100% + 2px)',
    bc: '$backdrop70',
    zIndex: '1',
    overflow: 'hidden',
    pe: 'none',
    clipPath: 'circle(0 at 50% 50%)',
    '--transition-duration': '0.4s',
  },
});

const CardButton = styled('div', {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%) scale(0)',
  zIndex: '1',
  pe: 'none',
  opacity: '0',
  transitionProperty: 'opacity, transform',
  transitionDelay: 'var(--transition-delay)',
  transitionDuration: 'var(--transition-duration)',
  transitionTimingFunction: 'var(--transition-easing)',
  willChange: 'opacity, transform',
  '& button': {
    '&:before': {
      content: 'none',
    },
  },
});

const ImageWrap = styled('div', {
  display: 'block',
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
  fontSize: 0,
  // userSelect: 'none',
  // '& picture, & img': {
  //   userSelect: 'none',
  // },
  variants: {
    size: {
      '16by9': {
        '&::before': {
          pt: [`${(9 / 16) * 100 + '%'}`],
        },
      },
      '2by3': {
        '&::before': {
          pt: [`${(3 / 2) * 100 + '%'}`],
        },
      },
      '3by2': {
        '&::before': {
          pt: [`${(2 / 3) * 100 + '%'}`],
        },
      },
      '7by4': {
        '&::before': {
          pt: [`${(4 / 7) * 100 + '%'}`],
        },
      },
      '4by3': {
        '&::before': {
          pt: [`${(3 / 4) * 100 + '%'}`],
        },
      },
      '3by4': {
        '&::before': {
          pt: [`${(4 / 3) * 100 + '%'}`],
        },
      },
      '1by1': {
        '&::before': {
          pt: [`${(1 / 1) * 100 + '%'}`],
        },
      },
      '21by9': {
        '&::before': {
          pt: [`${(9 / 21) * 100 + '%'}`],
        },
      },
      '2by1': {
        '&::before': {
          pt: [`${(1 / 2) * 100 + '%'}`],
        },
      },
      campaign: {
        '&::before': {
          pt: [`${(396 / 709) * 100 + '%'}`],
        },
      },
      service: {
        '&::before': {
          pt: [`${(465 / 395) * 100 + '%'}`],
          '@md': {
            pt: [`${(1 / 1) * 100 + '%'}`],
          },
          '@lg': {
            pt: [`${(465 / 395) * 100 + '%'}`],
          },
        },
      },
      banner: {
        '&::before': {
          pt: '144.92753623188406%',
          '@lg': {
            pt: '38.88888888888889%',
          },
        },
      },
      'banner-lcv': {
        '&::before': {
          content: 'none',
        },
      },
      'lcv-banner': {
        '&::before': {
          pt: [`${(4 / 3) * 100 + '%'}`],
          '@lg': {
            pt: [`${(9 / 21) * 100 + '%'}`],
          },
        },
      },
      testimonial: {
        '&::before': {
          pt: '114.66666666666667%',
          '@md': {
            pt: '105.679012345679%',
          },
        },
      },
      'product-cta': {
        '&::before': {
          pt: '125%',
        },
      },
      'product-service': {
        '&::before': {
          pt: '125%',
        },
      },
      'product-banner': {
        '&::before': {
          pt: '133.33333333333331%',
          '@lg': {
            pt: '50%',
          },
        },
      },
      'product-rear-body': {
        '&::before': {
          pt: [`${(450 / 1232) * 100 + '%'}`],
        },
      },
      'service-banner': {
        '&::before': {
          pt: '66.66666666666666%',
          '@lg': {
            pt: [`${(500 / 1344) * 100 + '%'}`],
          },
        },
      },
      'finance-banner': {
        '&::before': {
          pt: '66.66666666666666%',
          '@md': {
            pt: [`${(9 / 16) * 100 + '%'}`],
          },
          '@lg': {
            pt: [`${(500 / 1344) * 100 + '%'}`],
          },
        },
      },
      parts: {
        '&::before': {
          pt: '116.36363636363636%',
        },
      },
      'maintenance-banner': {
        '&::before': {
          pt: [`${(1 / 1) * 100 + '%'}`],
          '@lg': {
            pt: [`${(590 / 1440) * 100 + '%'}`],
          },
        },
      },
      'error-banner': {
        '&::before': {
          pt: [`${(1 / 1) * 100 + '%'}`],
          '@lg': {
            pt: [`${(500 / 1344) * 100 + '%'}`],
          },
        },
      },
      thankyou: {
        '&::before': {
          pt: [`${(1 / 1) * 100 + '%'}`],
          '@lg': {
            pt: [`${(684 / 1440) * 100 + '%'}`],
          },
        },
      },
      'isuzu-care-parts': {
        '&::before': {
          pt: '58.7248322147651%',
        },
      },
      't2h-thankyou': {
        '&::before': {
          pt: [`${(676 / 414) * 100 + '%'}`],
          '@md': {
            pt: [`${(1 / 1) * 100 + '%'}`],
          },
          '@lg': {
            pt: [`${(684 / 1440) * 100 + '%'}`],
          },
        },
      },
      splash: {
        '&::before': {
          pt: [`${(16 / 10) * 100 + '%'}`],
          '@media(min-height: 740px)': {
            pt: [`${(21 / 9) * 100 + '%'}`],
          },
          '@md': {
            '@media(orientation: portrait)': {
              pt: [`${(1366 / 1024) * 100 + '%'}`],
            },
            '@media(orientation: landscape)': {
              pt: [`${(1024 / 1366) * 100 + '%'}`],
            },
          },
          '@xl': {
            pt: [`${(640 / 1280) * 100 + '%'}`],
            '@media(min-height: 700px)': {
              pt: [`${(900 / 1440) * 100 + '%'}`],
            },
          },
        },
      },
      'moar-about-banner': {
        '&::before': {
          pt: [`${(21 / 9) * 100 + '%'}`],
          '@sm': {
            pt: [`${(16 / 9) * 100 + '%'}`],
          },
          '@lg': {
            pt: [`${(9 / 16) * 100 + '%'}`],
          },
          '@3xl': {
            pt: [`${(9 / 21) * 100 + '%'}`],
          },
        },
        '& img': {
          '@3xl': {
            objectPosition: 'bottom center',
          },
        },
      },
      'moar-about-info': {
        '&::before': {
          pt: [`${(480 / 390) * 100 + '%'}`],
        },
      },
      'moar-vehicle-banner': {
        '&::before': {
          pt: '66.66666666666666%',
          '@md': {
            pt: [`${(1 / 1) * 100 + '%'}`],
          },
          '@lg': {
            pt: [`${(850 / 1440) * 100 + '%'}`],
          },
          '@3xl': {
            pt: [`${(1 / 2) * 100 + '%'}`],
          },
        },
      },
      'part-detail-tire': {
        '&::before': {
          pt: [`${(2 / 3) * 100 + '%'}`],
          '@md': { pt: [`${(9 / 16) * 100 + '%'}`] },
        },
      },
    },
    fit: {
      cover: {
        objectFit: 'cover',
      },
      contain: {
        objectFit: 'contain',
      },
    },
    position: {
      center: {
        objectPosition: 'center',
      },
      top: {
        objectPosition: 'top',
      },
      bottom: {
        objectPosition: 'bottom',
      },
    },

    ratio: {
      true: {
        '&::before': {
          content: '""',
          display: 'block',
          position: 'relative',
          width: '100%',
        },
        '& picture, & img': {
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        },
        '& img': {
          objectFit: 'cover',
          objectPosition: 'center',
        },
      },
    },
    fade: {
      true: {
        '& picture img': {
          opacity: 0,
        },
        '&.is-load': {
          '& picture img': {
            '--transition-duration': '1s',
            opacity: 1,
            transitionProperty: 'opacity',
            transitionDelay: 'var(--transition-delay)',
            transitionDuration: 'var(--transition-duration)',
            transitionTimingFunction: 'var(--transition-easing)',
            willChange: 'opacity',
          },
        },
      },
    },
    fullheight: {
      true: {
        height: '100%',
        '& picture, & img': {
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        },
        '& img': {
          objectFit: 'cover',
          objectPosition: 'center',
        },
      },
    },
    auto: {
      true: {
        mx: 'auto',
        width: '100%',
        maxWidth: 'var(--width)',
        '&::before': {
          content: '""',
          display: 'block',
          position: 'relative',
          width: '100%',
          pt: 'var(--aspect-ratio)',
        },
        '& picture, & img': {
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        },
      },
    },
    objectFit: {
      contain: {
        '& img': {
          objectFit: 'contain',
        },
      },
      cover: {
        '& img': {
          objectFit: 'cover',
        },
      },
      fill: {
        '& img': {
          objectFit: 'fill',
        },
      },
      none: {
        '& img': {
          objectFit: 'none',
        },
      },
      'scale-down': {
        '& img': {
          objectFit: 'scale-down',
        },
      },
    },
    hoverImage: {
      true: {
        '& picture': {
          '--transition-duration': '1s',
          transitionProperty: 'transform',
          transitionDelay: 'var(--transition-delay)',
          transitionDuration: 'var(--transition-duration)',
          transitionTimingFunction: 'var(--transition-easing)',
          // Caused blink icon overlay on ios
          // willChange: 'transform',
        },
        '@hover': {
          '&.is-hover-image, &:hover': {
            cursor: 'pointer',
            '& picture': {
              transform: 'scale(1.05)',
            },
          },
        },
      },
    },
    hoverButton: {
      true: {
        '@hover': {
          '&.is-hover-button, &:hover': {
            [`& ${CardButton}`]: {
              transform: 'translate(-50%, -50%) scale(1)',
              opacity: '1',
            },
          },
        },
      },
    },
    hoverOverlay: {
      true: {
        '@lg': {
          '@hover': {
            '&.is-hover-overlay, &:hover': {
              [`& ${CardOverlay}`]: {
                '--transition-duration': '0.5s',
                clipPath: 'circle(70.71068% at 50% 50%)',
                transitionProperty: 'clip-path',
                transitionDelay: 'var(--transition-delay)',
                transitionDuration: 'var(--transition-duration)',
                // transitionTimingFunction: 'var(--transition-easing)',
              },
            },
          },
        },
      },
    },
    over: {
      true: {},
    },
  },
  defaultVariants: {
    fade: true,
  },
});

const BlurImg = styled('img', {});

const Img = styled('img', {
  '&.auto-img': {
    display: 'inline-block',
    maxWidth: '100%',
    mx: 'auto',
  },
  '&.t2h-img': {
    width: '100%',
  },
});

export interface ISrcset {
  src: string;
  breakpoint: number;
  fit?: boolean;
  width?: number;
  height?: number;
  maxwidth?: number;
  maxheight?: number;
  crop?: IRatio;
}
export interface ISource {
  src: string;
  width: number;
  media: string;
}
interface IProps {
  src: string;
  alt: string;
  auto?: boolean;
  autow?: number;
  autoh?: number;
  hoverChildImage?: boolean;
  hoverImage?: boolean;
  hoverButton?: boolean;
  hoverOverlay?: boolean;
  hoverChildOverlay?: boolean;
  srcset?: ISrcset[];
  source?: ISource[];
  fade?: boolean;
  lazy?: boolean;
  blur?: boolean;
  fullheight?: boolean;
  offsetY?: number;
  offsetX?: number;
  size?:
    | null
    | '16by9'
    | '7by4'
    | '2by3'
    | '3by2'
    | '4by3'
    | '3by4'
    | '1by1'
    | '21by9'
    | '2by1'
    | '7by4'
    | 'campaign'
    | 'service'
    | 'banner'
    | 'banner-lcv'
    | 'lcv-banner'
    | 'testimonial'
    | 'product-cta'
    | 'product-service'
    | 'product-banner'
    | 'product-rear-body'
    | 'service-banner'
    | 'parts'
    | 'maintenance-banner'
    | 'error-banner'
    | 'thankyou'
    | 'isuzu-care-parts'
    | 't2h-thankyou'
    | 'finance-banner'
    | 'splash'
    | 'moar-about-banner'
    | 'moar-about-info'
    | 'moar-vehicle-banner'
    | 'part-detail-tire';
  ratio?: boolean;
  quality?: number;
  imageWidth?: number;
  imageBlurWidth?: number;
  test?: ITest;
  onLoad?: () => void;
  priority?: boolean;
  css?: any;
  lazyBoundary?: string;
  cropByRatio?: IRatio;
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
}

interface INormaliseSrcset {
  srcset?: ISrcset[];
  quality?: number;
}

const normaliseSrcset = (params: INormaliseSrcset) => {
  const srcsetOrder = params.srcset.sort((a, b) => b.breakpoint - a.breakpoint);

  const srcsetBreakpoint = srcsetOrder.map((o) => {
    const src = o.src;
    const breakpoint = o.breakpoint;
    const width = o.width || o.breakpoint;
    const height = o.height || 0;
    const maxwidth = o.maxwidth || o.width;
    const maxheight = o.maxheight || o.height;
    const fit = o.fit;
    const crop = o.crop;

    const srcset = `${getImageOptions({
      src: src,
      width: width,
      height: height,
      crop: crop,
      fit: fit,
      quality: params.quality,
    })}, ${getImageOptions({
      src: src,
      width: maxwidth,
      height: maxheight,
      crop: crop,
      fit: fit,
      dpr: 2,
      quality: params.quality,
    })} 2x`;

    return {
      srcset,
      media: `(min-width: ${breakpoint}px)`,
      breakpoint: breakpoint,
    };
  });

  return srcsetBreakpoint.map((o, i) => {
    let media = '';
    if (i > 0) {
      const prev = srcsetBreakpoint[i - 1];
      media = `(min-width: ${o.breakpoint}px) and (max-width: ${
        prev.breakpoint - 1
      }px)`;
    } else {
      media = `(min-width: ${o.breakpoint}px)`;
    }

    return {
      srcset: o.srcset,
      media: media,
    };
  });
};

interface INormaliseSource {
  source?: ISource[];
  quality?: number;
}
const normaliseSource = (params: INormaliseSource) => {
  const sourceBreakpoint = params.source.map((o) => {
    const src = o.src;
    const width = o.width;
    const height = 0;

    const srcset = `${getImageOptions({
      src: src,
      width: width,
      height: height,
      quality: params.quality,
    })}, ${getImageOptions({
      src: src,
      width: width,
      height: height,
      dpr: 2,
      quality: params.quality,
    })} 2x`;

    return {
      srcset,
      media: o.media,
    };
  });

  return sourceBreakpoint;
};

const isSVG = (src: string) => {
  if (!src) return false;
  if (typeof src !== 'string') return;
  return src.includes('.svg');
};

interface IPreloadSvg {
  key?: string;
  src: string;
}

export const PreloadSVG: React.FunctionComponent<IPreloadSvg> = (
  props: IPreloadSvg
) => {
  return (
    <Head>
      <link key={props.key} rel="preload" as="image" href={props.src} />
    </Head>
  );
};
interface IPreloadImage {
  key?: number;
  id?: string;
  srcset?: ISrcset[];
  quality?: number;
}

export const PreloadImage: React.FunctionComponent<IPreloadImage> = (
  props: IPreloadImage
) => {
  const _srcset = () => {
    return normaliseSrcset({
      srcset: props.srcset,
      quality: props.quality,
    });
  };

  return (
    <Head>
      {_srcset().map((o, i) => (
        <link
          key={props.id ? props.id + i : i}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line react/no-unknown-property
          imagesrcset={o.srcset}
          rel="preload"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line react/no-unknown-property
          fetchpriority="high"
          as="image"
          media={o.media}
        />
      ))}
    </Head>
  );
};
PreloadImage.defaultProps = {
  srcset: [],
  quality: 85,
};

export const defaultSrc =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export const Image: React.FunctionComponent<IProps> = (props: IProps) => {
  const rootMargin = props.lazyBoundary
    ? props.lazyBoundary
    : `0px -${props.offsetX}px ${props.offsetY * -100}% -${props.offsetX}px`;
  // NOTE: left, right -1px for prevent siblings slide load in swiper slider

  const [refEl, inview] = useInViewRef({
    rootMargin: rootMargin,
  });
  const [loadBlur, setLoadBlur] = useState(false);
  const [hideBlur, setHideBlur] = useState(false);
  const [load, setLoad] = useState(false);
  const [timeID, setTimeID] = useState(null);

  const _srcBlur = () => {
    const targetSrc = getImageOptions({
      src: props.src,
      width: props.imageBlurWidth,
      height: 0,
      crop: props?.cropByRatio,
      fit: false,
      dpr: 1,
      blur: 40,
      quality: props.quality,
    });

    return inview ? targetSrc : defaultSrc;
  };

  const _src = () => {
    const targetSrc = isSVG(props.src)
      ? props.src
      : getImageOptions({
          src: props.src,
          width: props.imageWidth,
          crop: props?.cropByRatio,
          quality: props.quality,
        });

    if (props.blur && !loadBlur) {
      return defaultSrc;
    }

    if (!props.lazy) {
      return props.src ? targetSrc : defaultSrc;
    }

    return inview ? targetSrc : defaultSrc;
  };

  const _srcset = () => {
    if (!props.srcset || !props.srcset.length) {
      return [];
    }

    if (props.blur && !loadBlur) {
      return [];
    }

    if (!props.lazy) {
      return normaliseSrcset({
        srcset: props.srcset,
        quality: props.quality,
      });
    }

    return inview
      ? normaliseSrcset({
          srcset: props.srcset,
          quality: props.quality,
        })
      : [];
  };

  const _source = () => {
    if (!props.source || !props.source.length) {
      return [];
    }

    if (props.blur && !loadBlur) {
      return [];
    }

    if (!props.lazy) {
      return normaliseSource({
        source: props.source,
        quality: props.quality,
      });
    }

    return inview
      ? normaliseSource({
          source: props.source,
          quality: props.quality,
        })
      : [];
  };

  const onImageBlurLoaded = () => {
    setLoadBlur(true);
  };

  const onImageLoaded = async (e) => {
    if (e.target.src == defaultSrc) return;

    setLoad(true);
    props.onLoad && props.onLoad();

    await new Promise((resolve) => {
      const id = setTimeout(resolve, 1000);
      setTimeID(id);
    });
    setHideBlur(true);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeID);
    };
  }, [timeID]);

  return (
    <>
      {props.priority &&
        (isSVG(props.src) ? (
          <PreloadSVG src={props.src} />
        ) : (
          props.srcset.length && (
            <PreloadImage srcset={props.srcset} quality={props.quality} />
          )
        ))}
      <ImageWrap
        ref={refEl}
        className={`${load ? 'is-load' : ''} ${
          props.hoverChildImage ? 'is-hover-image' : ''
        } ${props.hoverChildOverlay ? 'is-hover-overlay' : ''}`}
        size={props.size}
        ratio={props.ratio}
        fade={props.fade}
        fullheight={props.fullheight}
        auto={props.auto}
        hoverImage={props.hoverImage}
        hoverButton={props.hoverButton}
        hoverOverlay={props.hoverOverlay}
        objectFit={props.objectFit}
        css={
          props.auto
            ? {
                '--aspect-ratio': `${(props.autoh / props.autow) * 100 + '%'}`,
                '--width': `${props.autow + 'px'}`,
                '--height': `${props.autoh + 'px'}`,
                ...props?.css,
              }
            : props?.css
        }
        data-test={props.test && props.test.dataTest}
      >
        {props.blur && !hideBlur && (
          <BlurImg
            src={_srcBlur()}
            alt={props.alt}
            onLoad={onImageBlurLoaded}
          />
        )}
        <picture onLoad={onImageLoaded}>
          {_source().length == 0 &&
            _srcset().map((o, i) => (
              <source key={i} srcSet={o.srcset} media={o.media} />
            ))}
          {_srcset().length == 0 &&
            _source().map((o, i) => (
              <source key={i} srcSet={o.srcset} media={o.media} />
            ))}
          <Img
            src={`${_src()}`}
            alt={props.alt}
            className={props.ratio ? 'ratio-img' : 'auto-img'}
          />
        </picture>
        {props.hoverOverlay && <CardOverlay />}
        {props.hoverButton && (
          <CardButton>
            <IconButton size="9" shadow variant="icon-button-white">
              <Icon>{getIcon('search')}</Icon>
            </IconButton>
          </CardButton>
        )}
      </ImageWrap>
    </>
  );
};

Image.defaultProps = {
  lazy: true,
  fade: true,
  blur: true,
  srcset: [],
  offsetY: 0,
  offsetX: 1,
  quality: 85,
  imageWidth: 320,
  imageBlurWidth: 100,
  priority: false,
};
Image.displayName = 'SharedElementImage';
