const PlacesTransportationCar: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.772 9.155L19.404 5.051C18.995 3.824 17.852 3 16.559 3H7.441C6.148 3 5.005 3.824 4.596 5.051L3.228 9.155C2.507 9.457 2 10.169 2 11V16C2 16.738 2.404 17.376 3 17.723V18.897V20C3 20.553 3.447 21 4 21H5C5.553 21 6 20.553 6 20V18H18V20C18 20.553 18.447 21 19 21H20C20.553 21 21 20.553 21 20V17.723C21.596 17.376 22 16.739 22 16V11C22 10.169 21.493 9.457 20.772 9.155ZM7.441 5H16.558C16.989 5 17.371 5.274 17.507 5.684L18.613 9H17.819H6.181H5.387L6.492 5.684C6.629 5.274 7.011 5 7.441 5ZM5.5 15C4.672 15 4 14.328 4 13.5C4 12.672 4.672 12 5.5 12C6.328 12 7 12.672 7 13.5C7 14.328 6.328 15 5.5 15ZM18.5 15C17.672 15 17 14.328 17 13.5C17 12.672 17.672 12 18.5 12C19.328 12 20 12.672 20 13.5C20 14.328 19.328 15 18.5 15Z" />
  </svg>
);
PlacesTransportationCar.displayName =
  'SharedElementIconsPlacesTransportationCar';
export default PlacesTransportationCar;
