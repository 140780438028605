const ShareLink: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.8057 4.40568C14.6799 2.53144 17.7192 2.53144 19.594 4.40568C21.4676 6.27993 21.4676 9.31867 19.594 11.1928L16.6239 14.1635C14.7492 16.0378 11.7094 16.0378 9.8352 14.1635C9.49682 13.8239 9.21887 13.446 9.00244 13.045L9.79343 12.253C10.0351 12.0113 10.3515 11.8707 10.6899 11.852C10.8261 12.1728 11.0239 12.4672 11.2776 12.72C11.7994 13.2418 12.4915 13.5285 13.2298 13.5285C13.967 13.5285 14.6603 13.2418 15.1815 12.72L18.151 9.7504C18.6723 9.22966 18.959 8.53643 18.959 7.79927C18.959 7.06101 18.6723 6.36888 18.151 5.84813C17.6297 5.3263 16.9365 5.03956 16.1993 5.03956C15.4621 5.03956 14.7689 5.3263 14.2476 5.84813L12.7299 7.36532C12.1059 7.15772 11.4468 7.05004 10.769 7.05004C10.5536 7.05004 10.3394 7.05995 10.1274 7.08302L12.8057 4.40568ZM11.2793 16.6225C11.9175 16.8389 12.5832 16.9488 13.2622 16.9488L13.2633 16.9489C13.4666 16.9489 13.6682 16.9389 13.8676 16.9203L11.1936 19.5943C9.31943 21.4686 6.28068 21.4686 4.40647 19.5943C2.53118 17.719 2.53118 14.6803 4.40647 12.806L7.37599 9.83537C9.2502 7.96113 12.29 7.96113 14.1642 9.83537C14.5031 10.1748 14.7816 10.5527 14.9981 10.9549L14.2082 11.7459C13.9665 11.9875 13.6495 12.1292 13.31 12.1468C13.1732 11.8261 12.975 11.5316 12.7212 11.2779C12.1999 10.7571 11.5078 10.4704 10.7695 10.4704C10.0324 10.4704 9.34024 10.7571 8.81841 11.2779L5.84889 14.2474C5.32706 14.7691 5.04032 15.4624 5.04032 16.1996C5.04032 16.9368 5.32706 17.63 5.84889 18.1507C6.37076 18.6726 7.06285 18.9593 7.80002 18.9593C8.53719 18.9593 9.23038 18.6726 9.75112 18.1507L11.2793 16.6225Z" />
  </svg>
);
ShareLink.displayName = 'SharedElementIconsShareLink';
export default ShareLink;
