const Download: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18 10H14V3H10V10H6L12 16L18 10ZM5 18V20H19V18H5Z" />
  </svg>
);
Download.displayName = 'SharedElementIconsFileDownload';
export default Download;
