const ShareYoutube: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.8303 6.84751C21.7172 6.39777 21.49 5.98608 21.1713 5.65356C20.8527 5.32105 20.4538 5.07937 20.0146 4.95265C17.3542 4.61731 14.6745 4.46619 11.994 4.50032C9.31352 4.46619 6.63384 4.61731 3.97349 4.95265C3.53422 5.07937 3.13535 5.32105 2.81672 5.65356C2.49809 5.98608 2.27087 6.39777 2.15775 6.84751C1.86747 8.55109 1.72663 10.2775 1.73688 12.0064C1.72632 13.7313 1.86717 15.4537 2.15775 17.1531C2.27637 17.5961 2.50604 18.0002 2.82431 18.3259C3.14258 18.6517 3.53855 18.8878 3.97349 19.0113C6.63301 19.3589 9.3127 19.5223 11.994 19.5003C14.6753 19.5223 17.355 19.3589 20.0146 19.0113C20.4538 18.8846 20.8527 18.6429 21.1713 18.3104C21.49 17.9779 21.7172 17.5662 21.8303 17.1165C22.1209 15.417 22.2617 13.6946 22.2512 11.9698C22.2593 10.2531 22.1185 8.53903 21.8303 6.84751V6.84751ZM10.0701 15.5883V9.07245L15.2046 12.3243L10.0701 15.5883Z" />
  </svg>
);
ShareYoutube.displayName = 'SharedElementIconsShareYoutube';
export default ShareYoutube;
