const SteeringWheel: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 1C5.93263 1 1 5.93263 1 12C1 18.0674 5.93263 23 12 23C18.0674 23 23 18.0674 23 12C23 5.93263 18.0674 1 12 1ZM3.33895 8.34105C4.77474 4.96 8.10947 2.59789 12 2.59789C15.8905 2.59789 19.2253 4.96 20.6611 8.34105C20.9389 9.01263 20.3832 9.75368 19.6653 9.68421C18.3916 9.59158 17.1179 9.52211 15.8442 9.47579C15.4505 9.45263 15.08 9.31368 14.8021 9.05895C14.0611 8.41053 13.0884 8.01684 12.0232 8.01684C10.9579 8.01684 9.98526 8.41053 9.24421 9.05895C8.94316 9.31368 8.57263 9.47579 8.20211 9.47579C6.90526 9.52211 5.63158 9.59158 4.35789 9.68421C3.61684 9.75368 3.06105 9.01263 3.33895 8.34105ZM8.80421 20.8463C5.6779 19.7116 3.31579 16.9789 2.73684 13.6211C2.62105 12.88 3.31579 12.3011 4.03368 12.5326C7.13684 13.5747 9.45263 16.2147 10.0316 19.4337L10.0779 19.7116C10.24 20.4526 9.52211 21.1011 8.80421 20.8463ZM12 13.9916C11.0274 13.9916 10.2168 13.2042 10.2168 12.2084C10.2168 11.2358 11.0042 10.4253 12 10.4253C12.9958 10.4253 13.7832 11.2126 13.7832 12.2084C13.7832 13.2042 12.9726 13.9916 12 13.9916ZM15.1958 20.8463C14.4779 21.1011 13.7832 20.4758 13.9221 19.7347L13.9684 19.4568C14.5474 16.2147 16.8632 13.5747 19.9663 12.5326C20.6611 12.3011 21.3789 12.9032 21.2632 13.6211C20.6842 16.9789 18.3221 19.7116 15.1958 20.8463Z" />
  </svg>
);
SteeringWheel.displayName = 'SharedElementIconsSteeringWheel';
export default SteeringWheel;
