const Pickup4Doors: React.FC = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9869 14.5789V10.345L16.656 10.6257L16.3947 7.5614C16.3784 7.25731 16.0682 7 15.9049 7L10.0924 7.25731C9.92913 7.25731 9.48829 7.42105 9.29237 7.70175L6.51673 10.6959L2.19001 11.304C1.81448 11.3508 1.50426 11.7953 1.45528 12.3333C1.45528 12.3333 1.09608 14.883 1.03077 15.0467C0.802191 15.6783 0.965464 16.4736 1.4063 16.8011L2.36961 17.0116C2.32063 16.6373 2.3043 16.2631 2.33695 15.8888C2.51655 14.0643 3.69212 12.7777 4.96564 13.035C6.23917 13.2923 7.13717 14.9765 6.95757 16.8011H17.2437C17.2274 16.5671 17.2111 16.3098 17.2274 16.0759C17.3417 14.228 18.452 12.8713 19.7418 13.035C21.0317 13.1988 21.9786 14.7894 21.8644 16.6373C22.746 16.5438 23.6114 16.2397 24.4277 15.7484V14.6023H23.9869V14.5789ZM11.8557 10.9532L7.2188 11L9.63524 8.42689C9.76586 8.28654 9.92913 8.19297 10.0924 8.19297L11.8557 8.1228V10.9532V10.9532ZM15.5947 8.00584L15.7743 10.8362L12.7374 10.9298V8.0994"
      fill="white"
    />
    <path
      d="M19.614 13.6898C19.5976 13.6898 19.5813 13.6898 19.565 13.6898C18.5527 13.6898 17.7363 14.8594 17.7363 16.3097C17.7363 17.76 18.5527 18.9295 19.565 18.9295C20.5773 18.9295 21.3936 17.76 21.3936 16.3097C21.3936 14.9062 20.6099 13.7366 19.614 13.6898ZM20.3324 16.4266C20.316 17.0348 19.9568 17.5026 19.5323 17.4793C19.1078 17.4559 18.7813 16.9412 18.7976 16.3331V16.3097C18.7976 15.7015 19.1405 15.2103 19.565 15.1869C19.5813 15.1869 19.5813 15.1869 19.5976 15.1869C20.0221 15.2103 20.365 15.7249 20.3487 16.3331C20.3324 16.3565 20.3324 16.3798 20.3324 16.4266Z"
      fill="white"
    />
    <path
      d="M4.71748 13.6898C4.70115 13.6898 4.68482 13.6898 4.6685 13.6898C3.65621 13.6898 2.83984 14.8828 2.83984 16.3331C2.83984 16.3565 2.83984 16.3798 2.83984 16.4032C2.85617 17.8535 3.68886 19.0231 4.70115 18.9997C5.71344 18.9763 6.51348 17.7833 6.49715 16.3331C6.49715 14.9062 5.71344 13.7366 4.71748 13.6898ZM5.43588 16.3798C5.41955 16.988 5.07668 17.4793 4.65217 17.4559C4.22766 17.4325 3.88479 16.9412 3.90112 16.3331V16.3097C3.90112 15.7015 4.24399 15.2103 4.6685 15.2103H4.68483C5.10933 15.2337 5.45221 15.7483 5.43588 16.3565C5.43588 16.3331 5.43588 16.3565 5.43588 16.3798Z"
      fill="white"
    />
  </svg>
);
Pickup4Doors.displayName = 'SharedElementIconsPickup4Doors';
export default Pickup4Doors;
