const PlayRound: React.FC = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.9387 44L42.9358 32L26.9387 20V44ZM32.2711 5.33337C17.5538 5.33337 5.60938 17.28 5.60938 32C5.60938 46.72 17.5538 58.6667 32.2711 58.6667C46.9884 58.6667 58.9328 46.72 58.9328 32C58.9328 17.28 46.9884 5.33337 32.2711 5.33337ZM32.2711 53.3334C20.5133 53.3334 10.9417 43.76 10.9417 32C10.9417 20.24 20.5133 10.6667 32.2711 10.6667C44.0289 10.6667 53.6005 20.24 53.6005 32C53.6005 43.76 44.0289 53.3334 32.2711 53.3334Z"
      fill="#DEDEDE"
    />
  </svg>
);
PlayRound.displayName = 'SharedElementIconsPlayRound';
export default PlayRound;
