const TransportationCar: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.772 9.15524L19.404 5.05124C18.995 3.82424 17.852 3.00024 16.559 3.00024H7.441C6.148 3.00024 5.005 3.82424 4.596 5.05124L3.228 9.15524C2.507 9.45724 2 10.1692 2 11.0002V16.0002C2 16.7382 2.404 17.3762 3 17.7232V18.8972V20.0002C3 20.5532 3.447 21.0002 4 21.0002H5C5.553 21.0002 6 20.5532 6 20.0002V18.0002H18V20.0002C18 20.5532 18.447 21.0002 19 21.0002H20C20.553 21.0002 21 20.5532 21 20.0002V17.7232C21.596 17.3762 22 16.7392 22 16.0002V11.0002C22 10.1692 21.493 9.45724 20.772 9.15524ZM7.441 5.00024H16.558C16.989 5.00024 17.371 5.27424 17.507 5.68424L18.613 9.00024H17.819H6.181H5.387L6.492 5.68424C6.629 5.27424 7.011 5.00024 7.441 5.00024ZM5.5 15.0002C4.672 15.0002 4 14.3282 4 13.5002C4 12.6722 4.672 12.0002 5.5 12.0002C6.328 12.0002 7 12.6722 7 13.5002C7 14.3282 6.328 15.0002 5.5 15.0002ZM18.5 15.0002C17.672 15.0002 17 14.3282 17 13.5002C17 12.6722 17.672 12.0002 18.5 12.0002C19.328 12.0002 20 12.6722 20 13.5002C20 14.3282 19.328 15.0002 18.5 15.0002Z" />
  </svg>
);
TransportationCar.displayName = 'SharedElementIconsTransportationCar';
export default TransportationCar;
