const Home: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 3L4 9V21H9V14H15V21H20V9L12 3Z" />
  </svg>
);
Home.displayName = 'SharedElementIconsHome';
export default Home;
