import { styled } from '@/stitches.config';
import { ITest, ITracking } from '@/types/tracking';

const StyledBox = styled('div', {
  boxSizing: 'border-box',
  variants: {
    variant: {
      white: {
        bc: '$white',
      },
      g2: {
        bc: '$gray200',
      },
      g3: {
        bc: '$gray300',
      },
      g8: {
        bc: '$gray800',
      },
      primaryDark: {
        bc: '$primary100',
      },
      primaryPaleRed: {
        bc: '$primary-200',
      },
    },
    rounded: {
      '4': {
        br: '$rounded-1',
      },
      '6': {
        br: '$rounded-1-5',
      },
      '8': {
        br: '$rounded-2',
      },
    },
    padding: {
      '16': {
        p: '$4',
      },
      '24': {
        p: '$6',
      },
    },
    border: {
      true: {
        bs: 'inset 0px 0px 0px 1px $primary100',
      },
    },
    position: {
      relative: {
        position: 'relative',
        width: '100%',
      },
      absolute: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      },
    },
  },
});

type IProps = React.ComponentProps<typeof StyledBox> & {
  children?: React.ReactNode;
  className?: string;
  tracking?: ITracking;
  test?: ITest;
  onClick?: () => void;
  onMouseOut?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  cy?: string;
  title?: string;
  scroll?: boolean;
};

export const Box: React.FunctionComponent<IProps> = (props: IProps) => {
  const options = {
    variant: props.variant,
    rounded: props.rounded,
    padding: props.padding,
    border: props.border,
    css: props.css,
    position: props.position,
  };
  return (
    <StyledBox
      {...options}
      className={props.className}
      onClick={props.onClick && props.onClick}
      onMouseOut={props.onMouseOut && props.onMouseOut}
      onMouseEnter={props.onMouseEnter && props.onMouseEnter}
      onMouseLeave={props.onMouseLeave && props.onMouseLeave}
      data-track={props.tracking && props.tracking.dataTrack}
      data-track-section={props?.tracking?.dataTrackSection}
      data-track-value={props.tracking && props.tracking.dataTrackValue}
      data-track-text={props.tracking && props.tracking.dataTrackText}
      data-track-url={props.tracking && props.tracking.dataTrackUrl}
      data-cy={props.cy}
      data-test={props.test && props.test.dataTest}
      data-test-value={props.test && props.test.dataTestValue}
      data-test-text={props.test && props.test.dataTestText}
      data-test-href={props.test && props.test.dataTestHref}
      data-test-category={props.test && props.test.dataTestCategory}
      data-test-price={props.test && props.test.dataTestPrice}
      data-test-business-type={props.test && props.test.dataTestBusiness}
      data-test-segment-type={props.test && props.test.dataTestSegment}
      data-test-body-type={props.test && props.test.dataTestBody}
      data-test-tag={props.test && props.test.dataTestTag}
      data-test-update-date={props.test && props.test.dataTestUpdateDate}
      data-test-pin={props.test && props.test.dataTestPin}
      title={props?.title}
      data-scroll={props?.scroll}
    >
      {props.children}
    </StyledBox>
  );
};
Box.displayName = 'SharedLayoutBox';
