const GeneralArticle: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 3.00024H5C3.9 3.00024 3 3.90024 3 5.00024V19.0002C3 20.1002 3.9 21.0002 5 21.0002H19C20.1 21.0002 21 20.1002 21 19.0002V5.00024C21 3.90024 20.1 3.00024 19 3.00024ZM14 17.0002H7V15.0002H14V17.0002ZM17 13.0002H7V11.0002H17V13.0002ZM17 9.00024H7V7.00024H17V9.00024Z" />
  </svg>
);
GeneralArticle.displayName = 'SharedElementIconsGeneralArticle';
export default GeneralArticle;
