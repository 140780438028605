import AccessTime from '@/components/shared/element/icons/access-time';
import Add from '@/components/shared/element/icons/add';
import ArrowBack from '@/components/shared/element/icons/arrow-back';
import ArrowDownward from '@/components/shared/element/icons/arrow-downward';
import ArrowForward from '@/components/shared/element/icons/arrow-forward';
import ArrowForward2 from '@/components/shared/element/icons/arrow-forward-2';
import ArrowUpward from '@/components/shared/element/icons/arrow-upward';
import Assignment from '@/components/shared/element/icons/assignment';
import Building from '@/components/shared/element/icons/building';
import Calculator from '@/components/shared/element/icons/calculator';
import Call from '@/components/shared/element/icons/call';
import Check from '@/components/shared/element/icons/check';
import Checkmark from '@/components/shared/element/icons/checkmark';
import ChevronLeft from '@/components/shared/element/icons/chevron-left';
import ChevronNorthEast from '@/components/shared/element/icons/chevron-north-east';
import ChevronRight from '@/components/shared/element/icons/chevron-right';
import Close from '@/components/shared/element/icons/close';
import Compare from '@/components/shared/element/icons/compare';
import Copy from '@/components/shared/element/icons/copy';
import Correct from '@/components/shared/element/icons/correct';
import Coupon from '@/components/shared/element/icons/coupon';
import Distance from '@/components/shared/element/icons/distance';
import Dots from '@/components/shared/element/icons/dots';
import East from '@/components/shared/element/icons/east';
import Email from '@/components/shared/element/icons/email';
import ExpandLess from '@/components/shared/element/icons/expand-less';
import ExpandMore from '@/components/shared/element/icons/expand-more';
import FaceSad from '@/components/shared/element/icons/face/sad';
import File from '@/components/shared/element/icons/file';
import Download from '@/components/shared/element/icons/file-download';
import Filter from '@/components/shared/element/icons/filter';
import Fix from '@/components/shared/element/icons/fix';
import Forum from '@/components/shared/element/icons/forum';
import Forward from '@/components/shared/element/icons/forward';
import GeneralArticle from '@/components/shared/element/icons/general/article';
import GiftReward from '@/components/shared/element/icons/gift-reward';
import Globe from '@/components/shared/element/icons/globe';
import Height from '@/components/shared/element/icons/height';
import Home from '@/components/shared/element/icons/home';
import Info from '@/components/shared/element/icons/info';
import List from '@/components/shared/element/icons/list';
import List2 from '@/components/shared/element/icons/list-2';
import LocalPhone from '@/components/shared/element/icons/local-phone';
import LocationPin from '@/components/shared/element/icons/location-pin';
import ManualDownload from '@/components/shared/element/icons/manual-download';
import ManualDownloadGray from '@/components/shared/element/icons/manual-download-gray';
import Map from '@/components/shared/element/icons/map';
import Money from '@/components/shared/element/icons/money';
import MyLocation from '@/components/shared/element/icons/my-location';
import North from '@/components/shared/element/icons/north';
import OpenInNew from '@/components/shared/element/icons/open-in-new';
import Order from '@/components/shared/element/icons/order';
import PhotoLibrary from '@/components/shared/element/icons/photo-library';
import PhotoLibraryOutLine from '@/components/shared/element/icons/photo-library-outline';
import Pickup from '@/components/shared/element/icons/pickup';
import Pickup4Doors from '@/components/shared/element/icons/pickup-4-doors';
import Place from '@/components/shared/element/icons/place';
import PlacesTransportationCar from '@/components/shared/element/icons/places-transportation/car';
import Play from '@/components/shared/element/icons/play';
import PlayRound from '@/components/shared/element/icons/play-round';
import PPV from '@/components/shared/element/icons/ppv';
import Receipt from '@/components/shared/element/icons/receipt';
import Search from '@/components/shared/element/icons/search';
import SellYourTruck from '@/components/shared/element/icons/sell-your-truck';
import ShareFacebook from '@/components/shared/element/icons/share/facebook';
import ShareInstagram from '@/components/shared/element/icons/share/instagram';
import ShareLine from '@/components/shared/element/icons/share/line';
import ShareLink from '@/components/shared/element/icons/share/link';
import ShareYoutube from '@/components/shared/element/icons/share/youtube';
import ShopStore from '@/components/shared/element/icons/shop-store';
import Slope from '@/components/shared/element/icons/slope';
import Facebook from '@/components/shared/element/icons/social/facebook';
import FacebookBlue from '@/components/shared/element/icons/social/facebook-blue';
import Instagram from '@/components/shared/element/icons/social/instagram';
import Line from '@/components/shared/element/icons/social/line';
import LineGreen from '@/components/shared/element/icons/social/line-green';
import Messenger from '@/components/shared/element/icons/social/messenger';
import Tiktok from '@/components/shared/element/icons/social/tiktok';
import Youtube from '@/components/shared/element/icons/social/youtube';
import South from '@/components/shared/element/icons/south';
import Stars from '@/components/shared/element/icons/stars';
import Clear from '@/components/shared/element/icons/status/clear';
import SteeringWheel from '@/components/shared/element/icons/steering-wheel';
import Torque from '@/components/shared/element/icons/torque';
import TransportationCar from '@/components/shared/element/icons/transportation/car';
import TriangleDown from '@/components/shared/element/icons/triangle-down';
import TriangleUp from '@/components/shared/element/icons/triangle-up';
import Truck from '@/components/shared/element/icons/truck';
import West from '@/components/shared/element/icons/west';
import { styled } from '@/stitches.config';
import { IIcon } from '@/types/shared/icons';

export const getIcon = (icon: IIcon) => {
  switch (icon) {
    case 'truck':
      return <Truck />;
    case 'compare':
      return <Compare />;
    case 'place':
      return <Place />;
    case 'map':
      return <Map />;
    case 'expand-less':
      return <ExpandLess />;
    case 'expand-more':
      return <ExpandMore />;
    case 'chevron-left':
      return <ChevronLeft />;
    case 'chevron-right':
      return <ChevronRight />;
    case 'chevron-north-east':
      return <ChevronNorthEast />;
    case 'arrow-back':
      return <ArrowBack />;
    case 'arrow-forward':
      return <ArrowForward />;
    case 'arrow-forward-2':
      return <ArrowForward2 />;
    case 'arrow-upward':
      return <ArrowUpward />;
    case 'arrow-downward':
      return <ArrowDownward />;
    case 'add':
      return <Add />;
    case 'close':
      return <Close />;
    case 'filter':
      return <Filter />;
    case 'clear':
      return <Clear />;
    case 'file-download':
      return <Download />;
    case 'forum':
      return <Forum />;
    case 'east':
      return <East />;
    case 'north':
      return <North />;
    case 'south':
      return <South />;
    case 'play':
      return <Play />;
    case 'play-round':
      return <PlayRound />;
    case 'west':
      return <West />;
    case 'steering-wheel':
      return <SteeringWheel />;
    case 'pickup':
      return <Pickup />;
    case 'facebook':
      return <Facebook />;
    case 'facebook-blue':
      return <FacebookBlue />;
    case 'line':
      return <Line />;
    case 'line-green':
      return <LineGreen />;
    case 'youtube':
      return <Youtube />;
    case 'instagram':
      return <Instagram />;
    case 'tiktok':
      return <Tiktok />;
    case 'messenger':
      return <Messenger />;
    case 'local-phone':
      return <LocalPhone />;
    case 'location-pin':
      return <LocationPin />;
    case 'list':
      return <List />;
    case 'list-2':
      return <List2 />;
    case 'access-time':
      return <AccessTime />;
    case 'stars':
      return <Stars />;
    case 'copy':
      return <Copy />;
    case 'search':
      return <Search />;
    case 'check':
      return <Check />;
    case 'info':
      return <Info />;
    case 'my-location':
      return <MyLocation />;
    case 'share-facebook':
      return <ShareFacebook />;
    case 'share-line':
      return <ShareLine />;
    case 'share-link':
      return <ShareLink />;
    case 'share-instagram':
      return <ShareInstagram />;
    case 'share-youtube':
      return <ShareYoutube />;
    case 'open-in-new':
      return <OpenInNew />;
    case 'gift-reward':
      return <GiftReward />;
    case 'fix':
      return <Fix />;
    case 'torque':
      return <Torque />;
    case 'calculator':
      return <Calculator />;
    case 'money':
      return <Money />;
    case 'home':
      return <Home />;
    case 'order':
      return <Order />;
    case 'assignment':
      return <Assignment />;
    case 'receipt':
      return <Receipt />;
    case 'globe':
      return <Globe />;
    case 'shop-store':
      return <ShopStore />;
    case 'face-sad':
      return <FaceSad />;
    case 'sell-your-truck':
      return <SellYourTruck />;
    case 'photo-library':
      return <PhotoLibrary />;
    case 'photo-library-outline':
      return <PhotoLibraryOutLine />;
    case 'correct':
      return <Correct />;
    case 'coupon':
      return <Coupon />;
    case 'email':
      return <Email />;
    case 'building':
      return <Building />;
    case 'call':
      return <Call />;
    case 'coupon':
      return <Coupon />;
    case 'triangle-up':
      return <TriangleUp />;
    case 'triangle-down':
      return <TriangleDown />;
    case 'manual-download':
      return <ManualDownload />;
    case 'manual-download-gray':
      return <ManualDownloadGray />;
    case 'forward':
      return <Forward />;
    case 'file':
      return <File />;
    case 'ppv':
      return <PPV />;
    case 'checkmark':
      return <Checkmark />;
    case 'pickup-4-doors':
      return <Pickup4Doors />;
    case 'slope':
      return <Slope />;
    case 'height':
      return <Height />;
    case 'distance':
      return <Distance />;
    case 'general-article':
      return <GeneralArticle />;
    case 'transportation-car':
      return <TransportationCar />;
    case 'dots':
      return <Dots />;
    case 'places-transportation-car':
      return <PlacesTransportationCar />;
  }

  return null;
};

export const Icon = styled('i', {
  position: 'relative',
  fontSize: '0',
  fontStyle: 'normal',
  lineHeight: '0',
  display: 'inline-block',
  verticalAlign: 'middle',
  transitionProperty: 'color',
  transitionDelay: 'var(--transition-delay)',
  transitionDuration: 'var(--transition-duration)',
  transitionTimingFunction: 'var(--transition-easing)',
  '& svg': {
    width: '100%',
    height: 'auto',
    color: 'CurrentColor',
    '& path, & polygon': {
      fill: 'CurrentColor',
    },
  },
  variants: {
    size: {
      '1': {
        size: 16,
        minWidth: 16,
      },
      '2': {
        size: 24,
        minWidth: 24,
      },
      '3': {
        size: 32,
        minWidth: 32,
      },
      '4': {
        size: 20,
        minWidth: 20,
      },
      '5': {
        size: 18,
        minWidth: 18,
      },
      '6': {
        size: 54,
        minWidth: 54,
      },
      '7': {
        size: 40,
        minWidth: 40,
      },
      '8': {
        size: 64,
        minWidth: 64,
      },
      xs: {
        size: 16,
        minWidth: 16,
      },
      sm: {
        size: 20,
        minWidth: 20,
      },
      base: {
        size: 24,
        minWidth: 24,
      },
      lg: {
        size: 32,
        minWidth: 32,
      },
      xl: {
        size: 36,
        minWidth: 36,
      },
      '2xl': {
        size: 40,
        minWidth: 40,
      },
      '3xl': {
        size: 48,
        minWidth: 48,
      },
      '4xl': {
        size: 56,
        minWidth: 56,
      },
      '5xl': {
        size: 64,
        minWidth: 64,
      },
    },
    variant: {
      'icon-current': {
        color: '$current',
      },
      'icon-red': {
        color: '$primary',
      },
      'icon-green-light': {
        color: '$green-light',
      },
      'icon-bronze': {
        color: '$bronze',
      },
      'icon-silver': {
        color: '$silver',
      },
      'icon-black': {
        color: '$black',
      },
      'icon-gray': {
        color: '$gray900',
      },
      'icon-gray-light': {
        color: '$gray700',
      },
      'icon-gray-lightest': {
        color: '$gray300',
      },
      'icon-white': {
        color: '$white',
      },
    },
  },
  defaultVariants: {
    size: '2',
  },
});
