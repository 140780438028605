const Messenger: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.85854 21.9883C5.5588 22.0548 5.3242 21.8332 5.32615 21.4675C5.33056 20.641 5.32273 19.8146 5.33154 18.9881C5.3335 18.8043 5.28061 18.6825 5.14053 18.5554C3.20641 16.8008 2.15045 14.6129 2.01233 12.0104C1.92417 10.3453 2.31061 8.7594 3.10747 7.29871C4.75312 4.28197 7.29015 2.5176 10.7137 2.09167C12.4235 1.87894 14.1152 2.02125 15.7246 2.67897C18.985 4.01105 21.4153 6.95004 21.9144 10.4612C22.0495 11.4138 22.0304 12.3825 21.8159 13.3219C20.9838 16.9661 18.8053 19.4493 15.274 20.6752C13.0279 21.4552 10.7529 21.3481 8.51018 20.5721C8.35444 20.5183 8.23885 20.5276 8.09681 20.618C7.42288 21.0484 6.74161 21.4704 6.06767 21.8983C5.99568 21.9438 5.92564 21.9731 5.85805 21.9883H5.85854ZM10.289 12.0881C10.3586 12.1507 10.4154 12.204 10.4747 12.2549C11.2799 12.9444 12.0855 13.6324 12.8898 14.3229C13.1719 14.5655 13.3732 14.5606 13.6455 14.2892C15.25 12.6886 16.8535 11.0876 18.4565 9.48559C18.5114 9.43082 18.5667 9.37262 18.6035 9.30563C18.6902 9.15061 18.6862 8.98777 18.5711 8.85182C18.4614 8.72174 18.3145 8.64448 18.1401 8.72712C17.9242 8.82933 17.7111 8.9374 17.501 9.05183C16.2908 9.71004 15.082 10.3702 13.8737 11.0309C13.7831 11.0803 13.7082 11.1121 13.6117 11.0284C12.7854 10.3145 11.9543 9.60539 11.1266 8.8929C10.819 8.62834 10.6481 8.6303 10.3606 8.91686C8.76144 10.5135 7.16232 12.1101 5.56467 13.7077C5.51031 13.762 5.46378 13.8251 5.41725 13.8867C5.26297 14.0892 5.38934 14.4623 5.65234 14.4716C5.81299 14.477 5.99225 14.4227 6.13624 14.3454C7.51986 13.6011 8.8976 12.8466 10.289 12.0881V12.0881Z" />
  </svg>
);
Messenger.displayName = 'SharedElementIconsSocialMessenger';
export default Messenger;
