const Height: React.FC = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 6L10.28 11.63L11.53 13.3L14.5 9.33L19.5 16H11.04L7.03 10.63L1.5 18H23.5L14.5 6ZM5.5 16L7.02 13.97L8.54 16H5.5Z"
      fill="white"
    />
  </svg>
);
Height.displayName = 'SharedElementIconsHeight';
export default Height;
