const Building: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17 2H7C5.896 2 5 2.896 5 4V21C5 21.552 5.447 22 6 22H10H14H18C18.553 22 19 21.552 19 21V4C19 2.896 18.104 2 17 2ZM11 16H8V14H11V16ZM11 12H8V10H11V12ZM11 8H8V6H11V8ZM16 16H13V14H16V16ZM16 12H13V10H16V12ZM16 8H13V6H16V8Z" />
  </svg>
);
Building.displayName = 'SharedElementIconsBuiding';
export default Building;
